import { createContext } from 'react';

export interface IMainContext {
  main?: {
    company: {
      [key: string]: {
        title: string,
        href: string,
        icon: string
      }
    },
    menu: {
      title: string,
      items: {
        id: string,
        name: string,
        href: string,
        title: string
      }[]
    }[],
    footer: {
      title: string,
      items: {
        id?: string,
        name: string,
        href: string,
        title: string,
        icon?: string
      }[]
    }[],
    homepageMenu: {
      id: string,
      name: string,
      href: string
    }[],
    topPanelLeft: {
      name: string,
      href: string,
      icon?: string
    }[],
    topPanelRight: {
      name: string,
      href: string,
      icon?: string
    }[]
  },
  home?: {},
  timezones?: []
};

const initialState = {};

export const MainContext = createContext<IMainContext>(initialState);
