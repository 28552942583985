import { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'next-i18next';
import { Button } from '@forma/forma-ui-kit';
import classNames from 'classnames';
import Link from '@/components/Link';
import Logo from '@/components/Logo';
import { MainContext } from '@/context/MainContext';
// import LangSwitchDropdown from '@/components/LangSwitchDropdown';

import styles from './footer.module.css';

const FooterColumn = ({ id, title, items }) => {
  return (
    <div className={styles.column} id={id}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.items}>
        {items.map(({ name, href, icon, title }, index) =>
          <div className={styles.item} key={index}>
            {href ? (
              <Link href={href} className={styles.link} title={title}>
                {icon && <ReactSVG className={styles.linkIcon} src={icon} wrapper="span" />} {name}
              </Link>
            ) : (
              <span className={classNames(styles.link, styles.cursorDefault)}>
                {icon && <ReactSVG className={styles.linkIcon} src={icon} wrapper="span" />} {name}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const Footer = () => {
  const { t } = useTranslation();
  const { main } = useContext(MainContext);

  if (!main) return null;

  const { email, phone, whatsapp, telegram, appStore, googlePlay } = main?.company;

  return (
    <footer className={styles.root}>
      <div className={classNames(styles.container, 'container')}>
        <div className={styles.company}>
          <Logo className={styles.logo} showText id="logo_footer" />
          <div className={styles.description}>
            {t('footer_description')}
          </div>

          <div>
            <Link href={phone.href} className={styles.contactsLink} title={phone.title}>
              <ReactSVG className={styles.contactsLinkIcon} src={phone.icon} wrapper="span" />
              {phone.title}
            </Link>
          </div>
          <div>
            <Link href={email.href} className={styles.contactsLink} title={email.title}>
              <ReactSVG className={styles.contactsLinkIcon} src={email.icon} wrapper="span" />
              {email.title}
            </Link>
          </div>
        </div>
        {main?.footer?.map((item) =>
          <FooterColumn {...item} key={item.id} />
        )}
      </div>
      <div className={classNames(styles.copyright, 'container')}>
        <div className={styles.copyrightText}>
          © 2025. Команда Форма. Все права защищены.
        </div>
        <div className={styles.copyrightButtons}>
          <Link
            href={appStore.href}
            target="_blank"
            className={styles.appLink}
            title={appStore.title}
          >
            <img src={appStore.icon} width={119} height={34} alt={appStore.title} />
          </Link>
          <Link
            href={googlePlay.href}
            target="_blank"
            className={styles.appLink}
            title={googlePlay.title}
          >
            <img src={googlePlay.icon} width={119} height={34} alt={googlePlay.title} />
          </Link>
          <Button
            as={Link}
            viewStyle="secondary"
            href={whatsapp.href}
            target="_blank"
            className={styles.copyrightButton}
            iconClassName={styles.copyrightButtonIcon}
            // title={whatsapp.title}
            icon={<ReactSVG src={whatsapp.icon} wrapper="span" />}
          />
          <Button
            as={Link}
            viewStyle="secondary"
            href={telegram.href}
            target="_blank"
            className={styles.copyrightButton}
            iconClassName={styles.copyrightButtonIcon}
            // title={telegram.title}
            icon={<ReactSVG src={telegram.icon} wrapper="span" />}
          />
          {/* <LangSwitchDropdown className={styles.langSwitch} align="top" /> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
