import { forwardRef, ReactNode } from 'react';
import { useRouter } from 'next/router';
import Link, { LinkProps } from 'next/link';
import i18nextConfig from '@/next-i18next.config';

interface LinkWrapProps extends LinkProps {
  children?: ReactNode,
  skipLocaleHandling?: boolean,
  target?: '_blank',
  href: string,
  title?: string,
  className?: string
}

const LinkWrap = forwardRef(({ children, skipLocaleHandling, ...rest }: LinkWrapProps, ref: any) => {
  const router = useRouter();
  let locale = rest.locale || router.query.locale as string || '';
  if (!i18nextConfig.i18n.locales.includes(locale)) locale = '';
  let href = rest.href || router.asPath;
  // if (href.indexOf('http') === 0) skipLocaleHandling = true
  if (href.slice(0, 1) !== '/') skipLocaleHandling = true;
  if (locale && !skipLocaleHandling) {
    href = href
      ? `/${locale}${href}`
      : router.pathname.replace('[locale]', locale);
  }

  return (
    <Link {...rest} href={href} ref={ref}>{children}</Link>
  );
});

LinkWrap.displayName = 'LinkWrap';

export default LinkWrap;
