import { forwardRef, useContext, useState } from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { Modal, ThemeContext } from '@forma/forma-ui-kit';
import Link from '@/components/Link';
import ClientWrap from '@/components/ClientWrap';

import styles from './submenu.module.css';

const lkUrl = process.env.LK_URL;

const Submenu = forwardRef(({ id, open, children, appLinks }, ref) => {
  const { t } = useTranslation();
  const { lang } = useContext(ThemeContext);
  const [isOpenDownload, setOpenDownload] = useState(false);

  return (
    <div className={classNames(styles.root, open && styles.open)}>
      <div id={id} className={styles.container} ref={ref}>
        <div className={styles.content}>
          {children}
        </div>
        <div className={styles.bottom}>
          <Link
            href={`${lkUrl}/register?lng=${lang}`}
            className={styles.link}
            target="_blank"
          >
            {t('start_free')}
          </Link>
          <div className={styles.divider} />
          <Link
            href="/request"
            className={styles.link}
          >
            {t('request_demo')}
          </Link>
          <div className={styles.divider} />
          <div
            className={styles.link}
            onClick={() => setOpenDownload(true)}
          >
            {t('download_app')}
          </div>

          <Link
            href="https://t.me/FormaHelperBot"
            className={classNames(styles.link, styles.connect)}
            target="_blank"
          >
            {t('connect')}
          </Link>
        </div>
      </div>
      <ClientWrap>
        <Modal
          open={isOpenDownload}
          onClose={setOpenDownload}
          title="Попробуйте мобильное приложение Формы"
        >
          <div className={styles.appModal}>
            {appLinks?.map(({ name, qrImage, image, href }) => (
              <Link className={styles.appLink} href={href} key={name}>
                <img src={qrImage} className={styles.appQr} width={200} height={200} alt={name} />
                <img src={image} className={styles.appIcon} width={160} alt={name} />
              </Link>
            ))}
          </div>
        </Modal>
      </ClientWrap>
      {/* <div className={classNames(styles.submenu)}>
      {items.map((elem) => (
        <Link
          href={elem.href}
          className={styles.item}
          key={elem.id}
        >
          <ReactSVG src={elem.icon} wrapper="span" />
          {elem.name}
        </Link>
      ))}
    </div> */}
    </div>
  );
});

Submenu.displayName = 'Submenu';

export default Submenu;
