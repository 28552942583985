import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { useSearchParams } from 'next/navigation';
import { useCookie } from 'next-cookie';
import Head from 'next/head';
import classNames from 'classnames';
import { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { ThemeContext, useViewport } from '@forma/forma-ui-kit';
import { MainContext } from '@/context/MainContext';
import { NotificationsProvider } from '@/context/NotificationsContext';
import { SharedProvider } from '@/context/SharedContext';
import useClient from '@/hooks/useClient';

import '@forma/forma-ui-kit/dist/style.css';

import Header from '@/components/Header';
import Footer from '@/components/Footer';
import TopPanel from '@/components/TopPanel';
import CookieNotification from '@/components/CookieNotification';
import NotificationsWrapper from '@/components/NotificationsWrapper';
import { breakpoints } from '@/breakpoints';
import useClientI18next from '@/hooks/useClientI18next';

import 'rc-slider/assets/index.css';

import '@/styles/globals.css';
import '@/styles/slider.css';

import 'slick-slider/slick/slick.css';
import 'slick-slider/slick/slick-theme.css';

const apiUrl = process.env.API_URL;
const isProduction = process.env.NODE_ENV === 'production';
const widgetsIgnorePaths = [
  '/sign', '/[locale]/sign',
  '/filling', '/[locale]/filling',
  '/poruchenie_na_obrabotku', '/[locale]/poruchenie_na_obrabotku',
  '/soglasie_na_obrabotku', '/[locale]/soglasie_na_obrabotku'
];

const App = ({ Component, pageProps }) => {
  const { i18n } = useTranslation();
  const { initialState, headersParse, ...restPageProps } = pageProps;
  const { pathname } = useRouter();
  const cookie = useCookie();
  const searchParams = useSearchParams();
  const viewport = useViewport(breakpoints);
  const isClient = useClient();

  const theme = {
    viewport: isClient ? viewport : headersParse?.type,
    currencies: initialState?.main?.currencies ?? {},
    isMenuOpen: false,
    lang: i18n.resolvedLanguage ?? i18n.language ?? headersParse?.language,
    apiUrl: apiUrl ?? '',
    token: ''
  };

  if (i18n.language === 'ru') registerLocale('ru', ru);

  useEffect(() => {
    const date = new Date();
    const utm = cookie.get('utm') ?? {};
    for (const [key, value] of searchParams.entries()) {
      if (key !== 'locale') utm[key] = value;
    }
    if (headersParse?.referrer) {
      utm.referrer = headersParse.referrer;
    }
    cookie.set('utm', JSON.stringify(utm), {
      path: '/',
      expires: new Date(date.setDate(date.getDate() + 7)),
      domain: '.forma.today'
    });
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    if (!isProduction) return;

    let leadback = document.getElementById('leadback');
    let quizgo = document.getElementById('quizgo');

    if (widgetsIgnorePaths.some(path => pathname.startsWith(path))) {
      // if (leadback) leadback.remove();
      // if (quizgo) quizgo.remove();
      return;
    }

    if (!leadback) {
      leadback = document.createElement('script');
      leadback.id = 'leadback';
      leadback.innerHTML = `var _emv = _emv || [];
      _emv['campaign'] = '4ea5d729c4b75c172d910bd9';

      (function() {
          var em = document.createElement('script'); em.type = 'text/javascript'; em.async = true;
          em.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'leadback.ru/js/leadback.js';
          var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(em, s);
      })();`;
      document.body.appendChild(leadback);
    }

    if (!quizgo) {
      quizgo = document.createElement('script');
      quizgo.id = 'quizgo';
      quizgo.src = '//panel.quizgo.ru/common?q=90861&force=1';
      document.body.appendChild(quizgo);
    }
  }, [pathname]);

  useClientI18next();

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <ThemeContext.Provider value={theme}>
        <MainContext.Provider value={initialState}>
          <SharedProvider>
            <NotificationsProvider>
              <div id="page" className={Component.pageClassName}>
                {Component.topPanel ? Component.topPanel() : <TopPanel />}
                {Component.header ? Component.header() : <Header />}

                <NotificationsWrapper />
                <CookieNotification />

                <main className={classNames('main-container content', Component.contentClassName)}>
                  <Component {...restPageProps} />
                </main>

                {Component.footer ? Component.footer() : <Footer />}
              </div>
            </NotificationsProvider>
          </SharedProvider>
        </MainContext.Provider>
      </ThemeContext.Provider>
    </>
  );
};

export default appWithTranslation(App);
