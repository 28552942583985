import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import Link from '@/components/Link';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import Logo from '@/components/Logo';
// import LangSwitchDropdown from '@/components/LangSwitchDropdown';
import { analytics } from '@/helpers/analytics';
import { MainContext } from '@/context/MainContext';
import SideMenuItems from './SideMenuItems';
import BurgerIcon from '../icons/BurgerIcon';

import styles from './side-menu.module.css';

const lkUrl = process.env.LK_URL;

const SideMenu = () => {
  const { t } = useTranslation();
  const { main } = useContext(MainContext);
  const { lang } = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false);

  const { phone, whatsapp, telegram } = main?.company;

  useEffect(() => {
    if (isOpen) document.body.classList.add('menu-open');
    else document.body.classList.remove('menu-open');

    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [isOpen]);

  return (
    <>
      <Button
        className={styles.menuButton}
        viewStyle="text"
        onClick={() => setIsOpen(true)}
        icon={<BurgerIcon />}
      />
      <div className={classNames(styles.overlay, isOpen && styles.open)} onClick={() => setIsOpen(false)} />
      <div className={classNames(styles.menu, isOpen && styles.open)}>
        <div className={styles.head}>
          <Logo id="logo_menu" iconClassName={styles.logoIcon} showText />
          <ReactSVG src="/icons/close.svg" className={styles.closeButton} onClick={() => setIsOpen(false)} />
        </div>
        <div className={styles.content}>
          <SideMenuItems onClickItem={() => setIsOpen(false)} />
          <div className={styles.links}>
            {main?.topPanelLeft.map(({ name, href, icon }) => (
              <Link
                href={href}
                className={styles.link}
                target={href.startsWith('http') ? '_blank' : undefined}
                onClick={() => setIsOpen(false)}
                key={name}
              >
                {icon && <ReactSVG className={styles.icon} src={icon} wrapper="span" />}
                {name}
              </Link>
            ))}
            {main?.topPanelRight.map(({ name, href, icon }) => (
              <Link
                href={href}
                className={styles.link}
                target={href.startsWith('http') ? '_blank' : undefined}
                onClick={() => setIsOpen(false)}
                key={name}
              >
                {icon && <ReactSVG className={styles.icon} src={icon} wrapper="span" />}
                {name}
              </Link>
            ))}
          </div>

          <div className={styles.buttonsRow}>
            <Button
              as={Link}
              href={whatsapp.href}
              className={styles.squareButton}
              viewStyle="secondary"
              target="_blank"
              iconClassName={styles.squareButtonIcon}
              icon={<ReactSVG src={whatsapp.icon} wrapper="span" />}
            />
            <Button
              as={Link}
              href={telegram.href}
              className={styles.squareButton}
              viewStyle="secondary"
              target="_blank"
              iconClassName={styles.squareButtonIcon}
              icon={<ReactSVG src={telegram.icon} wrapper="span" />}
            />
            <Link
              href={phone.href}
              className={styles.phoneLink}
              iconClassName={styles.icon}
            >
              {phone.title}
            </Link>
          </div>
          <div className={styles.buttonsRow}>
            {main?.appLinks.map(({ name, imageWhite, href }) => (
              <Link className={styles.appLink} href={href} key={name}>
                <img className={styles.appLinkIcon} src={imageWhite} height={34} alt={name} />
              </Link>
            ))}
          </div>
        </div>
        <div className={styles.buttonsBottom}>
          <Button
            as={Link}
            href={`${lkUrl}/register?lng=${lang}`}
            className={classNames(styles.button, styles.register)}
            viewStyle="primary"
            onClick={() => {
              analytics.clickRegisterHeader();
              setIsOpen(false);
            }}
            fullWidth
          >
            {t('start_free')}
          </Button>
          <Button
            as={Link}
            href="/request"
            className={classNames(styles.button, styles.signIn)}
            viewStyle="secondary"
            onClick={() => setIsOpen(false)}
            fullWidth
          >
            {t('request_demo')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
