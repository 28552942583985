import classNames from 'classnames';

import styles from './custom-submenus.module.css';

const SubmenuColumn = ({ title, children, className }) => {
  return (
    <div className={classNames(styles.column, className)}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};

export default SubmenuColumn;
