const BurgerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <g clip-path="url(#clip0_12147_12694-2335)">
        <path d="M0 6.5C0 5.94771 0.447715 5.5 1 5.5H23C23.5523 5.5 24 5.94771 24 6.5C24 7.05228 23.5523 7.5 23 7.5H1C0.447716 7.5 0 7.05228 0 6.5Z" fill="currentColor"></path>
        <path d="M0 12.5C0 11.9477 0.447715 11.5 1 11.5H23C23.5523 11.5 24 11.9477 24 12.5C24 13.0523 23.5523 13.5 23 13.5H1C0.447716 13.5 0 13.0523 0 12.5Z" fill="currentColor"></path>
        <path d="M1 17.5C0.447715 17.5 0 17.9477 0 18.5C0 19.0523 0.447716 19.5 1 19.5H23C23.5523 19.5 24 19.0523 24 18.5C24 17.9477 23.5523 17.5 23 17.5H1Z" fill="currentColor"></path>
      </g>
      <defs>
        <clipPath id="clip0_12147_12694-2335">
          <rect width="24" height="14" fill="white" transform="translate(0 5.5)"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export default BurgerIcon;
