import { useRef, useContext, useState } from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import Link from '@/components/Link';
import Logo from '@/components/Logo';
import { analytics } from '@/helpers/analytics';
import { MainContext } from '@/context/MainContext';
import HeaderMenu from './HeaderMenu';
import SideMenu from './SideMenu';
import Submenu from './Submenu';
import PhoneIcon from './icons/PhoneIcon';
import { CasesSubmenu, TemplatesSubmenu } from './CustomSubmenus';

import styles from './header.module.css';
import { useToggleSubmenu } from './useToggleSubmenu';

const lkUrl = process.env.LK_URL;

const Header = () => {
  const { t } = useTranslation();
  const { viewport, lang } = useContext(ThemeContext);
  const { main } = useContext(MainContext);
  const isPhoneOrTablet = ['phone', 'tabletS', 'tablet'].includes(viewport);
  const { phone } = main?.company;

  const templatesRef = useRef(null);
  const templatesButtonRef = useRef(null);
  const casesRef = useRef(null);
  const casesButtonRef = useRef(null);

  const { open: isOpenTemplates, close: closeTemplates } = useToggleSubmenu({ buttonRef: templatesButtonRef, containerRef: templatesRef });
  const { open: isOpenCases, close: closeCases } = useToggleSubmenu({ buttonRef: casesButtonRef, containerRef: casesRef });

  return (
    <header className={classNames(styles.root, styles.sticky)}>
      <div className={classNames(styles.container, 'container')}>
        <Logo
          id="header_logo"
          className={styles.logo}
          iconClassName={styles.logoIcon}
          textClassName={styles.logoText}
          showText
        />

        {/* {isPhoneOrTablet && (
          <div className={styles.navMobile}>
            <HeaderSelect />
          </div>
        )} */}
        {!isPhoneOrTablet && (
          <HeaderMenu
            openCases={isOpenCases}
            openTemplates={isOpenTemplates}
            casesRef={casesButtonRef}
            templatesRef={templatesButtonRef}
          />
        )}

        <div className={styles.buttons}>
          {isPhoneOrTablet ? (
            <>
              <Button
                as={Link}
                href={phone.href}
                className={styles.buttonPhone}
                viewStyle="text"
                icon={<PhoneIcon />}
              />
              <SideMenu />
            </>
          ) : (
            <>
              {/* <Button
                as={Link}
                href={`${lkUrl}/login?lng=${lang}`}
                className={classNames(styles.button, styles.signIn)}
                viewStyle="secondary"
                onClick={analytics.clickSignInHeader}
              // iconClassName={styles.signInIcon}
              // icon={<ReactSVG src="/icons/sign_in_shrink.svg" wrapper="span" />}
              >
                {t('login')}
              </Button> */}
              <Link href={phone.href} className={styles.phoneLink}>
                {phone.title}
              </Link>
              <Button
                as={Link}
                href="/request"
                className={classNames(styles.button, styles.signIn)}
                viewStyle="secondary"
              >
                {t('demo')}
              </Button>
              <Button
                as={Link}
                href={`${lkUrl}/register?lng=${lang}`}
                className={classNames(styles.button, styles.register)}
                viewStyle="primary"
                onClick={analytics.clickRegisterHeader}
              >
                {t('start_free')}
              </Button>
              {/* <RequestDemoButton
                className={classNames(styles.button, styles.requestDemo)}
              /> */}
              {/* <LangSwitch className={styles.lang} /> */}
            </>
          )}
        </div>
      </div>

      <Submenu open={isOpenCases} appLinks={main?.appLinks} ref={casesRef}>
        <CasesSubmenu onClickItem={closeCases} />
      </Submenu>

      <Submenu open={isOpenTemplates} appLinks={main?.appLinks} ref={templatesRef}>
        <TemplatesSubmenu onClickItem={closeTemplates} />
      </Submenu>
    </header>
  );
};

export default Header;
