import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import Link from '@/components/Link';
import { CasesSubmenu, TemplatesSubmenu } from '../../CustomSubmenus';

import styles from './side-menu-items.module.css';

const SideMenuItems = ({ onClickItem }) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const [isOpenCases, setOpenCases] = useState(false);
  const [isOpenTemplates, setOpenTemplates] = useState(false);

  return (
    <nav className={styles.items}>
      <div className={styles.item}>
        <div
          className={classNames(
            styles.link,
            isOpenCases && styles.open
          )}
          onClick={() => setOpenCases(prev => !prev)}
        >
          {t('use_cases')}
          <ReactSVG src="/icons/arrow-down.svg" className={styles.linkArrow} wrapper="span" />
        </div>
        {isOpenCases && (
          <div className={styles.children}>
            <CasesSubmenu onClickItem={onClickItem} />
          </div>
        )}
      </div>
      <div className={styles.item}>
        <Link
          href="/request"
          className={classNames(styles.link)}
          onClick={onClickItem}
        >
          {t('demo')}
        </Link>
      </div>
      <div className={styles.item}>
        <Link
          href="/tariffs"
          className={classNames(
            styles.link,
            (pathname === '/tariffs' || pathname === '/[locale]/tariffs') && styles.active
          )}
          onClick={onClickItem}
        >
          {t('tariffs')}
        </Link>
      </div>
      <div className={styles.item}>
        <Link
          href="/templates"
          className={classNames(
            styles.link,
            (pathname === '/templates' || pathname === '/[locale]/templates') && styles.active,
            isOpenTemplates && styles.open
          )}
          onClick={() => setOpenTemplates(prev => !prev)}
        >
          {t('ready_templates')}
          <ReactSVG src="/icons/arrow-down.svg" className={styles.linkArrow} wrapper="span" />
        </Link>
        {isOpenTemplates && (
          <div className={styles.children}>
            <TemplatesSubmenu onClickItem={onClickItem} />
          </div>
        )}
      </div>
    </nav>
  );
};

export default SideMenuItems;
