import { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import Image from 'next/image';
import { MainContext } from '@/context/MainContext';
import Link from '@/components/Link';
import NewLabel from '@/components/NewLabel';
import SubmenuColumn from './SubmenuColumn';

import styles from './custom-submenus.module.css';

const CasesSubmenu = ({ onClickItem }) => {
  const { main } = useContext(MainContext);

  return (
    <div className={styles.root}>
      <SubmenuColumn
        title="СФЕРЫ"
        className={styles.columnSolutions}
      >
        <div className={styles.solutions}>
          {main?.solutions.map(({ name, href, isNew }) => (
            <Link className={styles.solution} href={href} onClick={onClickItem} key={name}>
              {name} {isNew && <NewLabel />}
            </Link>
          ))}
        </div>
      </SubmenuColumn>
      <SubmenuColumn
        title="СТАТЬИ"
      >
        <div className={styles.articles}>
          {main?.articles.map(({ name, href, description, image }) => (
            <Link className={styles.article} href={href} onClick={onClickItem} target="blank" key={name}>
              <Image src={image} className={styles.articleImage} width={194} height={107} alt={name} />
              <div className={styles.articleContent}>
                <div className={styles.articleName}>{name}</div>
                <div className={styles.articleDescription}>{description}</div>
                <div className={styles.articleLink}>
                  Читать
                  <ReactSVG src="/icons/chevron-right.svg" className={styles.articleArrow} wrapper="span" />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </SubmenuColumn>
    </div>
  );
};

export default CasesSubmenu;
