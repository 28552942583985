import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import Link from '@/components/Link';

import styles from './logo.module.css';

const updateSvgIds = (svg, id) => {
  const animates = svg.querySelectorAll('animate, animateMotion, animateTransform');
  for (const elem of animates) {
    const pathSelector = elem.getAttribute('xlink:href');
    if (pathSelector) {
      elem.setAttribute('xlink:href', pathSelector + '_' + id);
      const paths = svg.querySelectorAll(pathSelector);
      for (const pathElem of paths) {
        pathElem.id = pathElem.id + '_' + id;
      }
    }
  }
};

const Logo = ({ id = 'logo', showText, className, iconClassName, textClassName }) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);
  const alt = t('main_company_name');

  return (
    <Link
      id={id}
      href="/"
      className={classnames(styles.link, className, isHover && styles.hover)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      title={t('links.product')}
    >
      <div className={classnames(styles.imageWrap, iconClassName)}>
        <img
          className={styles.image}
          src="/images/small_logo.svg"
          alt={alt}
          width="26px"
          height="26px"
        />
        <ReactSVG
          className={styles.imageHover}
          src="/images/small_logo_animated.svg"
          beforeInjection={(svg) => updateSvgIds(svg, id)}
        >
          {alt}
        </ReactSVG>
      </div>
      {showText && (
        <img
          className={classnames(styles.imageText, textClassName)}
          src="/images/logo_title.svg"
          alt={alt}
          width="70px"
          height="14px"
        />
      )}
      {/* <img className={styles.imageText} src={`/images/title_${i18n.language}.svg`} alt={alt} width="102px" height="19px" /> */}
    </Link>
  );
};

export default Logo;
