import { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { MainContext } from '@/context/MainContext';
// import LangSwitchDropdown from '@/components/LangSwitchDropdown';
import Link from '@/components/Link';
import NewLabel from '@/components/NewLabel';

import styles from './top-panel.module.css';

const TopPanel = () => {
  const { main } = useContext(MainContext);

  if (!main) return null;

  return (
    <div className={styles.root}>
      <div className={classNames('container', styles.container)}>
        <div className={styles.links}>
          {main?.topPanelLeft.map(({ name, href, icon }) => (
            <Link href={href} className={styles.link} target={href.startsWith('http') ? '_blank' : undefined} key={name}>
              {icon && <ReactSVG className={styles.icon} src={icon} wrapper="span" />}
              {name}
            </Link>
          ))}
        </div>
        <div className={styles.text}>
          <Link href="/request/" className={styles.link}>
            <NewLabel />
            Перенесем до 5-ти документов за 0₽
            <ReactSVG src="/icons/chevron-right.svg" className={styles.arrow} />
          </Link>
        </div>
        <div className={styles.links}>
          {main?.topPanelRight.map(({ name, href, icon }) => (
            <Link href={href} className={styles.link} target={href.startsWith('http') ? '_blank' : undefined} key={name}>
              {icon && <ReactSVG className={styles.icon} src={icon} wrapper="span" />}
              {name}
            </Link>
          ))}
          {/* {main?.company && (
            <>
              <Link href={phone.href} className={styles.link} title={phone.title}>
                <ReactSVG className={styles.icon} src={phone.icon} wrapper="span" />
                {phone.title}
              </Link>
              <Link href={whatsapp.href} className={styles.link} title={whatsapp.title} target="_blank">
                <ReactSVG className={styles.icon} src={whatsapp.icon} wrapper="span" />
              </Link>
              <Link href={telegram.href} className={styles.link} title={telegram.title} target="_blank">
                <ReactSVG className={styles.icon} src={telegram.icon} wrapper="span" />
              </Link>
            </>
          )} */}
          {/* <LangSwitchDropdown /> */}
        </div>
      </div>
    </div>
  );
};

export default TopPanel;
