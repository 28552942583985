import { useState } from 'react';
import { useEffect, useRef } from 'react';

const CLOSING_DELAY = 50;

export const useToggleSubmenu = ({ buttonRef, containerRef }) => {
  const timerRef = useRef(null);
  const button = buttonRef.current;
  const container = containerRef.current;
  const [isOpen, setOpen] = useState(false);

  const handleMouseMove = (e) => {
    if (button?.contains(e.target) || container?.contains(e.target)) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    } else {
      if (!timerRef.current) timerRef.current = setTimeout(() => handleCloseTemplates(), CLOSING_DELAY);
    }
  };

  const handleMouseEnter = () => {
    setOpen(true);
    document?.addEventListener('mousemove', handleMouseMove);
  };

  const handleCloseTemplates = () => {
    setOpen(false);
    document?.removeEventListener('mousemove', handleMouseMove);
  };

  useEffect(() => {
    // debugger;
    button?.addEventListener('mouseenter', handleMouseEnter);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      button?.removeEventListener('mouseEnter', handleMouseEnter);
      document?.removeEventListener('mousemove', handleMouseMove);
    };
  }, [button]);

  return {
    open: isOpen,
    close: () => setOpen(false)
  };
};
