import { useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import Link from '@/components/Link';

import styles from './header-menu.module.css';

const HeaderMenu = ({ openCases, openTemplates, casesRef, templatesRef }) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();

  return (
    <nav className={styles.links}>
      <div
        className={classNames(
          styles.link,
          openCases && styles.open
        )}
        ref={casesRef}
      >
        {t('use_cases')}
        <ReactSVG src="/icons/arrow-down.svg" className={styles.linkArrow} wrapper="span" />
      </div>
      <Link
        href="/request"
        className={classNames(styles.link)}
      >
        {t('demo')}
      </Link>
      <Link
        href="/tariffs"
        className={classNames(
          styles.link,
          (pathname === '/tariffs' || pathname === '/[locale]/tariffs') && styles.active
        )}
      >
        {t('tariffs')}
      </Link>
      <Link
        href="/templates"
        className={classNames(
          styles.link,
          (pathname === '/templates' || pathname === '/[locale]/templates') && styles.active,
          openTemplates && styles.open
        )}
        ref={templatesRef}
      >
        {t('ready_templates')}
        <ReactSVG src="/icons/arrow-down.svg" className={styles.linkArrow} wrapper="span" />
      </Link>
    </nav>
  );
};

export default HeaderMenu;
