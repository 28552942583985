import { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { MainContext } from '@/context/MainContext';
import Link from '@/components/Link';
import SubmenuColumn from './SubmenuColumn';

import styles from './custom-submenus.module.css';

const TemplatesSubmenu = ({ onClickItem }) => {
  const { main } = useContext(MainContext);

  return (
    <div className={styles.root}>
      <SubmenuColumn
        title="ПОПУЛЯРНЫЕ"
        className={styles.columnPopular}
      >
        <div className={styles.templates}>
          {main.templates.map(({ name, href }) => (
            <Link className={styles.template} href={href} onClick={onClickItem} key={name}>
              <img className={styles.templateIcon} src="/icons/seo/doc-icon.svg" width={42} height={42} alt={name} />
              <span className={styles.templateContent}>
                <span className={styles.templateName}>{name}</span>
                <span className={styles.templateLink}>Смотреть</span>
              </span>
            </Link>
          ))}
        </div>
      </SubmenuColumn>
      <SubmenuColumn
        title="КАТЕГОРИИ"
      >
        <div className={styles.categories}>
          {main.categories.map(({ id, name, href, icon, title }) => (
            <Link className={styles.category} href={href} title={title} onClick={onClickItem} key={id}>
              <img className={styles.categoryIcon} src={icon} width={42} height={42} alt={name} />
              <span className={styles.categoryName}>{name}</span>
            </Link>
          ))}
          <Link className={classNames(styles.category, styles.categoryAll)} onClick={onClickItem} href="/templates">
            <span className={styles.categoryName}>
              Все категории
            </span>
            <ReactSVG src="/icons/chevron-right.svg" className={styles.categoryArrow} wrapper="span" />
          </Link>
        </div>
      </SubmenuColumn>
    </div>
  );
};

export default TemplatesSubmenu;
