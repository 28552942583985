import classNames from 'classnames';
import Logo from '@/components/Logo';

import styles from './header.module.css';

const HeaderSimple = () => {
  return (
    <header className={classNames(styles.root, styles.simple)}>
      <div className={classNames(styles.container, 'container')}>
        <div className={styles.logo}>
          <Logo showText />
        </div>
      </div>
    </header>
  );
};

export default HeaderSimple;
